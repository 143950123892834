import { useEffect } from "react";
import { useSelector } from "react-redux";
import { stateTypes } from "../../store";
import { createSportFrame } from "../../actions/actions";
import {DEFAULT_CURRENT_PAGE} from '../../utils/constants'

const SportIframe = () => {
  const {digitainApiToken, currentPage, sportUrl, section, theme, lang, betHistoryOpen} = useSelector((state:stateTypes):any => state);


  [digitainApiToken, section, sportUrl, theme, lang].map((param, i) => useEffect(() => {
    console.log(`Creating sport iframe triggered by ${['digitainApiToken', 'section', 'sportUrl', 'theme', 'lang'][i]} 
url=${sportUrl}, token=${digitainApiToken}, section=${section} theme=${theme} lang=${lang}`);
    if(sportUrl) {
      createSportFrame(digitainApiToken, currentPage, sportUrl, theme, lang);
      
    } else {
      console.warn(`Bad sportUrl: ${sportUrl}, skip creating SportFrame`);
    }
  }, [param]));
  // useEffect(() => {
  //   console.log(`sport iframe url=${sportUrl}. token=${digitainApiToken}, section=${section} theme=${theme} lang=${lang}`);
  //   createSportFrame(digitainApiToken, currentPage, sportUrl, theme, lang);
  // }, [digitainApiToken, section, sportUrl, theme, lang]);

  useEffect(() => {
    console.log('effect worked', currentPage);
    if(currentPage === DEFAULT_CURRENT_PAGE){ return;}
    console.debug('change current page', currentPage);
    (window as any).SportFrame.openPage(currentPage);
    
  }, [currentPage, betHistoryOpen]);

  return (<div id="sport_div_iframe"></div>);
};

export default SportIframe;
