import { toggleModal } from "../actions/actions";

const RulesModal = ({theme}: { theme: string}) => {
  return (
    <>
      <div
        className="modal_overlay"
        onClick={(ev) => {
          if ((ev.target as HTMLDivElement).id !== "modal") {
            toggleModal();
          } else {
            return false;
          }
        }}
      >
        <div className="modal" id="modal" onClick={(ev) => ev.stopPropagation()}>
          <div className="modal_header">
            <div id="wrapper_heading"></div>
            <div
              id="popup-wrapper-close"
              onClick={(ev) => {
                if (
                  (ev.target as HTMLDivElement).id === "popup-wrapper-close"
                ) {
                  toggleModal();
                } else {
                  return false;
                }
              }}
            >
              x
            </div>
          </div>
          <iframe src={`https://rules.bingoboom.ru/${theme ? '?theme=' + theme : ''}`}></iframe>
        </div>
      </div>
    </>
  );
};

export default RulesModal;
