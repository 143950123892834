import { toggleWsToGcliOpen, setTokensFromGcli } from "../actions/actions";


export const openWsGCli = () => {

    const wsGCli = new WebSocket(`ws://127.0.0.1:1313`);

    wsGCli.onopen = () => {
        console.log("Connection with gCli established");
        toggleWsToGcliOpen(true);
        wsGCli.send(JSON.stringify({
            kind: "get_user_data"
          }));
    }

    wsGCli.onerror = () => {
        console.error("Error while connecting with gCli");
    }

    wsGCli.onclose = () => {
        console.log("Connection to gCLI was closed");
    }

    

    wsGCli.onmessage = (ev) => {
        const parsedData = JSON.parse(ev.data);
        console.log('wsGCli.onmessage(). parsedData=', parsedData)
        if(parsedData){
            const {msg_kind, kind, data} = parsedData;
            const messageType = kind || msg_kind;

            if(messageType){
                switch (messageType) {
                    case "get_user_data":
                        setTokensFromGcli(data);
                        break;
                    case "srv_lost_msg":
                        //errorsHandler(666);
                        break;
                    case "srv_connection_restore_msg":
                        //errorsHandler(null);
                        break;
                    case "card_removed":
                        // signOut(true);
                        break;
                    default:
                        console.debug(`Unknown kind message, kind=${messageType}`)
                        break;
                    
                }
            }
        }
    };

    return wsGCli;
}
