import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stateTypes } from "../../store";
import {
  createCybersportFrame
} from "../../actions/actions";


const CybersportIframe = () => {
  const {digitainApiToken, sportUrl, view, theme, lang, currentPage} = useSelector((state: stateTypes): any => state);
  const [cyberSportFrame, setCSF] = useState<any>();
  useEffect(() => {
    console.debug(`cybersport iframe. token=${digitainApiToken} view=${view} theme=${theme} lang=${lang}`);
    (window as any).SportFrame.elem = null;
    createCybersportFrame(digitainApiToken, sportUrl, view, theme, lang, setCSF);
  }, [digitainApiToken, view, lang]);

  useEffect(() => {
    let page = "/";
    switch(currentPage) {
      case "Overview":
        page = "/live";
        break;
      case "BetsHistory":
        page = "/betHistory";
        break;
      case "Upcoming":
        page = "/preMatch";
        break;
      default:
        page = "/";
    }
    console.debug('change cyber current page', currentPage, '>>', page);
    if(cyberSportFrame) cyberSportFrame.navigateTo(page);
  }, [currentPage]);

  useEffect(() => cyberSportFrame && cyberSportFrame.dispatchEvent("setTheme", theme), [theme]);

  return (
    <div id="application-container"></div>
  );
};

export default CybersportIframe;
