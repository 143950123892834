import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {decodeAccessToken, decodeToken} from '../utils/helpers'
import {signOut, errorsHandler} from '../actions/actions'

interface authArgsTypes {
  phone?: string;
  pin?: string;
  idTokenInit: string;
  cardTag?: string;
  qr?: string;
}

interface requestTypes {
  idToken: string;
  domain: string;
  event: string;
  data?: any;
}

const ttl = process.env.NEXT_PUBLIC_V3_AUTH_REQUEST_MESSAGE_TTL_MS
    ? parseInt(process.env.NEXT_PUBLIC_V3_AUTH_REQUEST_MESSAGE_TTL_MS) : 10000;

const timeout = process.env.NEXT_PUBLIC_V3_URL_REST_TTL_MS
    ? parseInt(process.env.NEXT_PUBLIC_V3_URL_REST_TTL_MS) : 12000;

export const sendRequest = ({idToken, domain, event, data}: requestTypes) => new Promise((res, rej) => {
  axios
      .post(`${process.env.NEXT_PUBLIC_V3_URL_REST}`, {
        id: `${uuidv4()}`,
        locale: "ru",
        domain,
        event,
        data,
        ttl,
        tokens: {
          id: idToken,
          access: "access",
        },
      }, {timeout})
      .then((resp) => res(resp.data))
      .catch((err) => rej(err));
});

export const sendAuthRequest = async ({
  phone,
  pin,
  idTokenInit,
  cardTag,
  qr,
}: authArgsTypes) => {
  const url = new URLSearchParams(window.location.search);
  const hallId: string =
    (url.get("hallId") as string) || (url.get("hall_id") as string);

  let dataForSubscribe: {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    gamblerName: string;
    delayForRefreshTokens: number;
    refreshError: boolean;
    authError?: boolean;
    sportLanguages: Array<string>;
  } = {
    idToken: "",
    accessToken: "",
    refreshToken: "",
    gamblerName: "",
    delayForRefreshTokens: 0,
    refreshError: false,
    authError: false,
    sportLanguages: ['ru'],
  };

  const data: any = {
    hallId: +hallId,
  };
  let event = "";

  if (cardTag && !phone && !qr) {
    event = "signInAccountByCardTag";
    data.cardTag =cardTag;
  }
  if (qr && !cardTag && !phone) {
    event = "signInAccountByQrCodePin";
    data.qrCode = qr;
    data.pin = pin;
  }
  if (!qr && !cardTag && phone) {
    event = "signInAccountByPhonePin";
    data.phone = phone;
    data.pin = pin;
  }

  console.log("Отправляем данные для авторизации...");

  try {
    let dataFromServer: any = await sendRequest({
      idToken: idTokenInit,
      domain: "auth",
      event,
      data,
    });

    const decodedAccessToken = decodeAccessToken(dataFromServer.data.accessToken)
    console.log(decodedAccessToken);

    const decodedToken = decodeToken(dataFromServer.data.idToken);
    dataForSubscribe = {
      idToken: dataFromServer.data.idToken,
      accessToken: dataFromServer.data.accessToken,
      refreshToken: dataFromServer.data.refreshToken,
      gamblerName: decodedAccessToken.data.accountId,
      delayForRefreshTokens: decodedToken.exp * 1000 - Date.now() - (2 * 60 * 1000),
      refreshError: false,
      sportLanguages: decodedToken.sportLanguages,
    };
  } catch (err) {
    console.error('Login failed', err);
    errorsHandler(101);
    setTimeout(() => errorsHandler(null), 5000);
    setTimeout(() => signOut(true), 5000);
    dataForSubscribe = {
      idToken: "",
      accessToken: "",
      refreshToken: "",
      gamblerName: "",
      delayForRefreshTokens: 0,
      refreshError: false,
      authError: true,
      sportLanguages: ['ru'],
    };
  }

  return dataForSubscribe;
};

export const refreshTokens = async (refreshToken: string) => {
  let dataForSubscribe: {
    idToken: string;
    accessToken: string;
    refreshToken: string;
    gamblerName: string;
    delayForRefreshTokens: number;
    refreshError: boolean;
    sportLanguages: Array<string>;
  } = {
    idToken: "",
    accessToken: "",
    refreshToken: "",
    gamblerName: "",
    delayForRefreshTokens: 0,
    refreshError: false,
    sportLanguages: ['ru'],
  };

  try {
    let dataFromServer: any = await sendRequest({
      idToken: refreshToken,
      domain: "auth",
      event: "refreshTokens",
      data: {}
    });

    const decodedToken = decodeToken(dataFromServer.data.idToken);
    const decodedAccessToken = decodeAccessToken(dataFromServer.data.accessToken)
    dataForSubscribe = {
      idToken: dataFromServer.data.idToken,
      accessToken: dataFromServer.data.accessToken,
      refreshToken: dataFromServer.data.refreshToken,
      gamblerName: decodedAccessToken.data.accountId,
      sportLanguages: decodedToken.sportLanguages,
      delayForRefreshTokens: decodedToken.exp * 1000 - Date.now() - (2 * 60 * 1000),
      refreshError: false,
    };
  } catch (err) {
    console.error("Error while try to refresh tokens, ", err);
    errorsHandler(101);
    setTimeout(() => errorsHandler(null), 5000);
    setTimeout(() => signOut(true), 5000);
  }

  return dataForSubscribe;
};

export const getAccountSettings = async (idToken: string) => {
  let dataForSubscribe: {language: any; view: any; theme: any;} = {
    language: 'ru',
    view: "",
    theme: "",
  };

  try {
    let dataFromServer: any = await sendRequest({
      idToken,
      domain: "account",
      event: "getAccountSettings",
      data: {}
    });

    dataForSubscribe = {
      language: dataFromServer.data.language,
      view: dataFromServer.data.view,
      theme: dataFromServer.data.theme,
    };
  } catch (err) {
    console.error("Error get account settings, ", err);
    errorsHandler(666);
    setTimeout(() => errorsHandler(null), 5000);
  }

  return dataForSubscribe;
};

export const setAccountSettings = async (idToken: string, data: {language?: any; view?: any; theme?: any;}) => {
  try {
    await sendRequest({
      idToken,
      domain: "account",
      event: "accountSettingsModified",
      data
    });
  } catch (err) {
    console.error("Error set account settings, ", err);
    errorsHandler(666);
    setTimeout(() => errorsHandler(null), 5000);
  }
};

export const readClientNotification = async (idToken: string, data: {clientNotificationId: number}) => {
  try {
    await sendRequest({
      idToken,
      domain: "notification",
      event: "readClientNotification",
      data
    });
  } catch (err) {
    console.error("Error set notification read, ", err);
    errorsHandler(666);
    setTimeout(() => errorsHandler(null), 5000);
  }
};

export const pressNotification = async (idToken: string, data: {clientNotificationId: number, accountId: string}) => {
  try {
    await sendRequest({
      idToken,
      domain: "notification",
      event: "pressPromoButton",
      data
    });
  } catch (err) {
    console.error("Error set notification promobutton pressed, ", err);
    errorsHandler(666);
    setTimeout(() => errorsHandler(null), 5000);
  }
};