import { useEffect } from "react";
import Head from "next/head";
import { HeaderV3, AuthForm } from "@bb/offline-gameplace-header";
import {NotificationScreen}  from "@bb/offline-client-notification";
import { useSelector, useDispatch, useStore } from "react-redux";
import { stateTypes } from "../store";
import { openWsGCli } from "../services/gCliConnection";
import RulesModal from "../components/rulesModal";
// import AuthForm from "../components/authForm";
import ErrorPanel from "../components/errorPanel";
import SportIframe from "../components/iframes/sportIframe";
import CybersportIframe from "../components/iframes/cybersportIframe";
import sportUrls from "../actions/config";

import {
  setDispatcher,
  getDigitainToken,
  logOut,
  signOut,
  subscribeToV3Events,
  setBalanceId,
  sendBalance,
  createLinkList,
  refreshV3,
  toggleAuthForm,
  setGcliToState,
  setWalletId,
  getDigitainTokenSeparate,
  getTokensFromV3,
  cardServiceConnect,
  openBetsHistory,
  getPartnerId,
  setSportUrl,
  setPhonePin,
  setQrPin,
  checkQrValid,
  getDeviceType,
  setHallId,
  setRefreshHandle,
  setStore,
  setSettings,
  readNotificationsFunc,
  pressPromoFunc
} from "../actions/actions";


export default function Home() {
  const stateToProps = useSelector((state: stateTypes): any => {
    return {
      idTokenInit: state.idTokenInit,
      idToken: state.idToken,
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      refreshHandle: state.refreshHandle,
      gamblerName: state.gamblerName,
      balance: state.balance,
      freebets: state.freebets,
      cardTag: state.cardTag,
      delayForRefreshTokens: state.delayForRefreshTokens,
      phone: state.phone,
      qr: state.qr,
      pin: state.pin,
      refreshError: state.refreshError,
      balanceTypeList: state.balanceTypeList,
      wsGCliOpen: state.wsGCliOpen,
      digitainApiToken: state.digitainApiToken,
      iFrameReady: state.iFrameReady,
      rulesOpen: state.rulesOpen,
      isCybersoprt: state.isCybersoprt,
      freebetsFromServer: state.freebetsFromServer,
      currentBalanceId: state.currentBalanceId,
      wsToV3: state.wsToV3,
      subscribeInterval: state.subscribeInterval,
      authFormOpen: state.authFormOpen,
      errorCode: state.errorCode,
      isError: state.isError,
      walletId: state.walletId,
      section: state.section,
      partnerId: state.partnerId,
      hallId: state.hallId,
      sportUrl: state.sportUrl,
      authStatus: state.authStatus,
      qrCheckStatus: state.qrCheckStatus,
      deviceType: state.deviceType,
      enableAutoLogin: state.enableAutoLogin,
      sportLanguages: state.sportLanguages,
      theme: state.theme,
      view: state.view,
      lang: state.lang,
      isNotificationOpen: state.isNotificationOpen,
      unreadNotificationsCount: state.unreadNotificationsCount,
      notifications: state.notifications
    };
  });

  const {
    idToken,
    accessToken,
    gamblerName,
    balanceTypeList,
    wsGCliOpen,
    digitainApiToken,
    rulesOpen,
    freebetsFromServer,
    currentBalanceId,
    refreshToken,
    refreshHandle,
    wsToV3,
    delayForRefreshTokens,
    subscribeInterval,
    authFormOpen,
    errorCode,
    isError,
    walletId,
    section,
    cardTag,
    phone,
    qr,
    pin,
    idTokenInit,
    partnerId,
    hallId,
    sportUrl,
    authStatus,
    qrCheckStatus,
    deviceType,
    enableAutoLogin,
    sportLanguages,
    theme,
    view,
    lang,
    isNotificationOpen,
    unreadNotificationsCount,
    notifications
  }: stateTypes = stateToProps;

  const dispatch = useDispatch();
  const store = useStore();

  setDispatcher(dispatch);
  setStore(store);

  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    if (!walletId) {
      setWalletId(url.get("wallet_id"));
    }
    if (!partnerId) {
      getPartnerId(url.get("partner_id") || url.get("partnerId"));
    }
    if (!deviceType) {
      getDeviceType(url.get("device"));
    }
    if (!hallId) {
      setHallId(url.get("hall_id") || url.get("hallId"));
    }
  }, [walletId, partnerId, deviceType, hallId]);
  useEffect(() => {
    console.log("hook for set sport url by partnerId:", partnerId, sportUrls);
    if (!partnerId) {
      setSportUrl("");
    } else {
      setSportUrl(partnerId);
    }
  }, [partnerId]);

  useEffect(() => {
    if (!wsGCliOpen && walletId) {
      setGcliToState(openWsGCli());
    }
    if (!walletId) {
      cardServiceConnect();
    }
  }, [wsGCliOpen, walletId]);

  useEffect(() => {
    if (phone.length === 11 && pin.length === 6 && !cardTag) {
      getTokensFromV3({ phone, pin, idTokenInit });
    } else if (!phone && !pin && cardTag) {
      getTokensFromV3({ idTokenInit, cardTag });
    } else if (qr && pin.length === 6 && !cardTag) {
      getTokensFromV3({ qr, pin, idTokenInit });
    }
  }, [phone, pin, qr, cardTag]);

  useEffect(() => {
    console.log('GET DIGITAIN TOKEN TRIGGERED BY:', {walletId, phone, pin, qr, cardTag, enableAutoLogin});
    if (digitainApiToken === "-" && walletId && enableAutoLogin) {
      getDigitainToken(walletId, hallId);
    }
    if (
      !walletId &&
      ((phone.length === 11 && pin.length === 6) ||
        cardTag ||
        (qr && pin.length === 6))
    ) {
      getDigitainTokenSeparate({ phone, qr, pin, cardTag, hallId });
    }
  }, [walletId, phone, pin, qr, cardTag, enableAutoLogin]);

  useEffect(() => {
    if (wsToV3) {
      const delay = subscribeInterval * 60 * 1000 - 180000;
      setTimeout(
        () =>
          subscribeToV3Events(
            { idToken, accessToken },
            currentBalanceId,
            subscribeInterval,
            wsToV3
          ),
        delay
      );
    }
    if (idToken && !wsToV3) {
      subscribeToV3Events(
        { idToken, accessToken },
        currentBalanceId,
        subscribeInterval,
        wsToV3
      );
    }
  }, [wsToV3, idToken]);

  useEffect(() => {
    if (refreshToken){
      let handle = setTimeout(() => refreshV3(refreshToken), delayForRefreshTokens);
      setRefreshHandle(handle);
    }
  }, [refreshToken]);

  useEffect(() => {
    if (refreshToken === ''){
      clearTimeout(refreshHandle);
      setRefreshHandle(null);
    }
  }, [refreshToken]);


  useEffect(() => {
    if (balanceTypeList.length && digitainApiToken !== "-" && idToken) {
      sendBalance(
        idToken,
        digitainApiToken,
        freebetsFromServer,
        currentBalanceId
      );
    }
  }, [currentBalanceId, section, digitainApiToken, idToken]);

  useEffect(() => {
    if (balanceTypeList[0]?.id === "money") {
      setBalanceId("money");
    }
  }, [balanceTypeList]);

  useEffect(() => {
    if (pin.length < 6) {
      dispatch({
        type: "SET_AUTH_STATUS",
        payload: "not_started",
      });
    }
  }, [pin]);

  const getBalanceId = (id: string) => {
    setBalanceId(id);
  };

  const getDataCallback = ({
    pin,
    phone,
    qr,
  }: {
    pin?: string;
    phone?: string;
    qr?: string;
  }) => {
    if (pin && phone) {
      setPhonePin({
        phone: `7${phone}`,
        pin: pin,
      });
    }
    if (qr && pin) {
      setQrPin({
        qr: qr,
        pin: pin,
      });
    }
    if (qr && !pin) {
      checkQrValid(qr, hallId);
    }
  };

  const setQrStatusCallback = (status: "pending" | "error" | "success") => {
    dispatch({
      type: "SET_QR_STATUS",
      payload: status
    })
  };

  const transformedWalletId = (id: string | null) => id === null ? 0 : parseInt(id);

  return (
    <>
      <Head>
        <title>SportBoom</title>
        <link rel="icon" href="/favicon.ico" />
        <script
          type="text/javascript"
          src={`${sportUrls.default.url}/js/Partner/IntegrationLoader.min.js`}
        ></script>
        <script
          type="text/javascript"
          src={`${sportUrls.default.url}/js/partner/bootstrapper.min.js`}
        ></script>
      </Head>
      <HeaderV3
        country="ru"
        screenType={section === "sport" ? "sport" : "cybersport"}
        linkList={createLinkList()}
        balanceTypeList={balanceTypeList}
        user={gamblerName}
        isFaqOpen={false}
        bgColor="black"
        loginFunc={() => toggleAuthForm()}
        logoutFunc={() => walletId ? logOut() : signOut(true)}
        openFaqFunc={() => null}
        fnForBetsButton={() => openBetsHistory()}
        getBalanceId={getBalanceId}
        backFunc={() =>
            isNotificationOpen ? dispatch({type: "TOGGLE_NOTIFICATIONS"})
                : walletId ? logOut() : signOut(true)
        }
        showBackButton={!!(walletId || gamblerName)}
        sportLanguages={sportLanguages}
        theme={theme}
        view={view}
        lang={lang}
        setAccountSettings={setSettings}
        wallet={false}
        isNotificationEnabled={true}
        isLatinoViewEnabled={!!process.env.NEXT_PUBLIC_LATINO_VIEW_ENABLED || false}
        notificationCount={unreadNotificationsCount}
        isNotificationOpen={isNotificationOpen}
        openNotificationFunc={() => dispatch({type: "TOGGLE_NOTIFICATIONS"})}
      />
      {authFormOpen && (
        <AuthForm
          setQrStatusCallback={setQrStatusCallback}
          qrWorkerPath="/qr-worker.min.js"
          qrCheckStatus={qrCheckStatus}
          authStatus={authStatus}
          deviceType={(deviceType as "terminal" | "tablet") || "terminal"}
          closeFunc={() => {
            if (qrCheckStatus === "error") {
              setQrPin({ qr: "", pin: "" });
              dispatch({
                type: "SET_QR_STATUS",
                payload: "pending",
              });
            }
            toggleAuthForm();
          }}
          getDataCallback={getDataCallback}
        />
      )}
      {rulesOpen && <RulesModal theme={theme}/>}

      {isNotificationOpen && (walletId || gamblerName)
          ? <NotificationScreen 
              country="ru"
              theme={theme} 
              appType={section} 
              list={notifications} 
              readNotificationFunc={readNotificationsFunc}
              pressPromoFunc={pressPromoFunc}
              accountId={transformedWalletId(walletId)}
            />
          : sportUrl && (view === "LatinoView" || section === "cybersport")
              ? <CybersportIframe />
              : <SportIframe />
      }

      <ErrorPanel errorCode={errorCode} isError={isError} />
    </>
  );
}
