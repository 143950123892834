const ErrorPanel = ({ errorCode, isError }: { errorCode: number | null, isError: boolean }) => {
  const errors = [
    {
      code: 101,
      text: "Не удалось авторизоваться, попробуйте снова",
    },
    {
      code: 102,
      text: "Ошибка продления сессии. Пожалуйста, повторите вход."
    },
    {
      code: 666,
      text: "Ошибка. Потеряна связь с сервером"
    }
  ];

  const currentError = errors.find((el) => el.code === errorCode);

  return (
    <div
      className={isError ? "error-visible error" : "error-hidden error"}
    >
      <span className="error-text">{currentError?.text}</span>
    </div>
  );
};

export default ErrorPanel;
