interface SportUrlsTypes {
    [parnterId: string]: {
        url: string
    }
}

const sportUrls: SportUrlsTypes = {
    default: {
        url: process.env.NEXT_PUBLIC_SPORT_URL_DEFAULT || 'https://betboomterminal.betadigitain.com'
    },
    // Основная интеграция (спутник)
    "200" : {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_200 || 'https://betboomterminal.betadigitain.com'
    },
    // Основная интеграция (задежка 0 сек)
    "201" : {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_201 || 'https://betboomterminal.betadigitain.com'
    },
    // Основная интеграция (задежка 4 сек)
    "202": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_202 || 'https://betboomterminal.betadigitain.com'
    },
    // Сочи интеграция (спутник)
    "215": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_215 || 'https://betboomterminal.betadigitain.com'
    },
    // Сочи интеграция (задежка 0 сек)
    "216": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_216 || 'https://betboomterminal.betadigitain.com'
    },
    // Сочи интеграция (задежка 4 сек)
    "217": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_217 || 'https://betboomterminal.betadigitain.com'
    },
    // Арбат интеграция (спутник)
    "230": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_230 || 'https://betboomterminal.betadigitain.com'
    },
    // Арбат интеграция (задежка 0 сек)
    "231": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_231 || 'https://betboomterminal.betadigitain.com'
    },
    // Арбат интеграция (задежка 4 сек)
    "232": {
        url: process.env.NEXT_PUBLIC_SPORT_URL_PARTNER_232 || 'https://betboomterminal.betadigitain.com'
    },
}

export const getAuthServiceUrl = () => {
    const {host, pathname} = new URL(process.env.NEXT_PUBLIC_AUTH_SERVICE_URL || "");
    const {protocol} = window.location;
	const path = pathname.length > 1 ? pathname : "";
    
    return `${protocol}//${host}${path}`;
};

export default sportUrls;