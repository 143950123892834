import axios from "axios";
import {refreshTokens, sendAuthRequest, getAccountSettings, setAccountSettings, readClientNotification, pressNotification} from "../services/authV3";
import {notificationType} from "../store";
import jwt from "jsonwebtoken";
import {v4 as uuidv4} from "uuid";
import sportUrls, {getAuthServiceUrl} from "./config";
import {decodeToken} from '../utils/helpers'

let dispatch: any = null;
let prolongTimeout: any = null;
let store: any = null;
let cyberSportFrame: any = null;

const refreshTimeout: number = 10 * 60 * 1000;

const sendGcli = (kind: string, data: any) => {
  if(process.env.NODE_ENV === 'development') {
    const {wsGcli} = store.getState();
    if (wsGcli) {
      wsGcli.send(JSON.stringify({kind, data}));
    } else {
      // console.error('No gcli mock');
    }
  }
};

export const setDispatcher = (dispatchFromIndex: any) => {
  dispatch = dispatchFromIndex;
};

export const setStore = (storeFromIndex: any) => {
  store = storeFromIndex;
};

export const toggleModal = () => {
  dispatch({ type: "TOGGLE_RULES_MODAL" });
};

export const toggleAuthForm = () => {
  dispatch({
    type: "TOOGLE_AUTHFORM",
  });
};

export const setSportUrl = (partnerId: string | null) => {
  if (!partnerId || !sportUrls[partnerId]) {
    dispatch({
      type: "SET_SPORT_URL",
      payload: `${sportUrls.default.url}`,
    });
    return;
  }
  if (sportUrls[partnerId]) {
    dispatch({
      type: "SET_SPORT_URL",
      payload: `${sportUrls[partnerId].url}`,
    });
  }
};

export const getDigitainToken = (walletId: string | null = "", hallId: string | null = "") => {
  if (!walletId) {
    console.debug("Empty wallet ID");
    return;
  } else {
    axios({
      method: "post",
      // headers: {
      //   "X-Forwarded-For": "172.16.2.136"
      // },
      url: `${getAuthServiceUrl()}/signin${hallId ? `?hall_id=${hallId}` : ""}`,
      data: {
        walletId: walletId,
      },
    }).then((res) => {
        dispatch({
          type: "DIGITAIN_TOKEN_GET",
          payload: res.data.data.token,
        });
        sendAuthAlive(res.data.data.token);
      })
      .catch((err) => {
        dispatch({
          type: "DIGITAIN_TOKEN_GET",
          payload: "-",
        });
        errorsHandler(101);
        setTimeout(() => errorsHandler(null), 5000);
        signOut();
        console.error(err);
      });
  }
};

export const getPartnerId = (id: string | null) => {
  dispatch({
    type: "SET_PARTNER_ID",
    payload: id,
  });
};

export const getDeviceType = (deviceType: string | null) => {
  dispatch({ type: "SET_DEVICE_TYPE", payload: deviceType });
};

export const setHallId = (id: string | null) => {
  dispatch({
    type: "SET_HALL_ID",
    payload: id,
  });
};

export const sendAuthAlive = (token: string) => {
  const authAliveRequest = () => {
    return axios
      .post(`${getAuthServiceUrl()}/alive`, {token})
      .then((res) => {
        console.debug("Digitain token was prolong, response =>>> ", res);
        sendAuthAlive(token);
      })
      .catch((err) => {
        console.error("Error while prolongation digitain token, ", err);
          errorsHandler(102);
          setTimeout(() => errorsHandler(null), 5000);
          dispatch({
            type: "SET_AUTH_STATUS",
            payload: "error",
          });
          signOut();
      });
  };
  if(prolongTimeout) clearTimeout(prolongTimeout);
  prolongTimeout = setTimeout(() => authAliveRequest(), refreshTimeout);
};

export const getDigitainTokenSeparate = (data: {
  phone: string | "";
  qr: string | "";
  pin: string | "";
  cardTag: string | "";
  hallId: string | null;
}) => {
  if (!data.phone && !data.pin && !data.cardTag) {
    return;
  } else if (data.phone && data.pin && !data.cardTag) {
    axios({
      method: "post",
      // headers: {
      //   "X-Forwarded-For": "172.16.2.136"
      // },
      url: `${getAuthServiceUrl()}/signin${data.hallId ? `?hall_id=${data.hallId}` : ""}`,
      data: {
        phone: data.phone,
        pin: data.pin,
      },
    })
      .then((res) => {
        dispatch({
          type: "DIGITAIN_TOKEN_GET",
          payload: res.data.data.token,
        });
        sendAuthAlive(res.data.data.token);
      })
      .catch((err) => {
        console.error(err);
        errorsHandler(101);
        setTimeout(() => errorsHandler(null), 5000);
        signOut();
      });
  } else if (!data.phone && !data.pin && data.cardTag) {
    axios({
      // headers: {
      //   "X-Forwarded-For": "172.16.2.136"
      // },
      method: "post",
      url: `${getAuthServiceUrl()}/signin${data.hallId ? `?hall_id=${data.hallId}` : ""}`,
      data: {
        card: data.cardTag,
      },
    })
      .then((res) => {
        dispatch({
          type: "DIGITAIN_TOKEN_GET",
          payload: res.data.data.token,
        });
        sendAuthAlive(res.data.data.token);
      })
      .catch((err) => {
        console.error(err);
        errorsHandler(101);
        setTimeout(() => errorsHandler(null), 5000);
        signOut();
      });
  } else if (data.qr && data.pin && !data.cardTag) {
    axios({
      method: "post",
      // headers: {
      //   "X-Forwarded-For": "172.16.2.136"
      // },
      url: `${getAuthServiceUrl()}/signin${data.hallId ? `?hall_id=${data.hallId}` : ""}`,
      data: {
        qrcode: data.qr,
        pin: data.pin,
      },
    })
      .then((res) => {
        dispatch({
          type: "DIGITAIN_TOKEN_GET",
          payload: res.data.data.token,
        });
        sendAuthAlive(res.data.data.token);
      })
      .catch((err) => {
        console.error(err);
        errorsHandler(101);
        setTimeout(() => errorsHandler(null), 5000);
        signOut();
      });
  }
};

export const setPhonePin = (data: { phone: string; pin: string }) => {
  dispatch({
    type: "SET_PHONE_PIN",
    payload: data,
  });
};

export const setQrPin = (data: { qr: string; pin: string }) => {
  dispatch({
    type: "SET_QR_PIN",
    payload: data,
  });
};

export const checkQrValid = (qr: string, hallId: string | null) => {
  axios({
    method: "post",
    /* headers: {
      "X-Forwarded-For": "172.16.2.136"
    }, */
    url: `${getAuthServiceUrl()}/qrcheck${hallId ? `?hall_id=${hallId}` : ""}`,
    data: {
      qrcode: qr,
    },
  })
    .then((res) => {
      console.debug("Qr check service answer ", res);
      if (!res.data.error) {
        dispatch({ type: "SET_QR_STATUS", payload: "success" });
      } else {
        dispatch({ type: "SET_QR_STATUS", payload: "error" });
      }
    })
    .catch((err) => {
      dispatch({ type: "SET_QR_STATUS", payload: "error" });
      console.error("Erorr while check qr code!", err);
    });
};

export const cardServiceConnect = () => {
  const {wsToCardService} = store.getState();

  if (!wsToCardService) {
    const cardServiceConnection = new WebSocket(
      `${process.env.NEXT_PUBLIC_CARD_SERVICE_URL}`
    );

    dispatch({
      type: "SET_WS_TO_CARD_SERVICE",
      payload: cardServiceConnection,
    });

    cardServiceConnection.onopen = () => {
      console.debug("Connection with card service established indeed");
    };
    cardServiceConnection.onerror = (err) => {
      console.error("Get error from card service! ", err);
    };
    cardServiceConnection.onclose = (ev) => {
      if (ev.wasClean) {
        console.debug("Connection with card service was closed clean");
      } else {
        console.debug("Connection with card service was droped");
      }
    };
    cardServiceConnection.onmessage = (ev) => {
      const message = JSON.parse(ev.data);
      console.debug('cardServiceConnection.onmessage', message);
      if (message.event === "inserted") {

        signOut();
        dispatch({
          type: "SET_CARD_TAG",
          payload: message.card,
        });

      } else if (message.event === "removed") {
        signOut(true);
      }
    };
  }
};

export const toggleWsToGcliOpen = (status: boolean) => {
  dispatch({
    type: "TOGGLE_WS_GCLI_OPEN",
    payload: status,
  });
};

export const setWalletId = (walletId: string | null) => {
  dispatch({
    type: "SET_WALLET_ID",
    payload: walletId,
  });
};

export const setGcliToState = (gcli: WebSocket) => {
  dispatch({
    type: "SET_GCLI_TO_STATE",
    payload: gcli,
  });
};

export const setTokensFromGcli = (data: {
  idToken: string;
  accessToken: string;
  refreshToken: string;
}) => {
  const {enableAutoLogin} = store.getState();
  console.debug('setTokensFromGcli', data, enableAutoLogin);

  if((!data.idToken || !data.accessToken || !data.refreshToken) && enableAutoLogin) {
    signOut();
  } else getAccountSettings(data.idToken).then(res => {
    const {sportLanguages} = decodeToken(data.idToken);
    console.log(sportLanguages, res.language);
    res.language && dispatch({
      type: "SWITCH_LANG", payload: sportLanguages.includes(res.language) ? res.language : sportLanguages[0]
    });
    res.view && dispatch({type: "SWITCH_VIEW", payload: res.view});
    res.theme && dispatch({type: "SAVE_USER_THEME", payload: res.theme});
  });

  dispatch({
    type: "SET_TOKENS_FROM_GCLI",
    payload: data,
  });
};

export const getTokensFromV3 = (data: {
  phone?: string;
  qr?: string;
  pin?: string;
  idTokenInit: string;
  cardTag?: string;
}) => {
  dispatch({ type: "SET_AUTH_STATUS", payload: "pending" });
  sendAuthRequest(data).then((res) => {
    if (!res.authError) {
      dispatch({
        type: "GET_TOKENS_FROM_V3",
        payload: {
          idToken: res.idToken,
          accessToken: res.accessToken,
          refreshToken: res.refreshToken,
          gamblerName: res.gamblerName,
          delayForRefreshTokens: res.delayForRefreshTokens,
          sportLanguages: res.sportLanguages,
        },
      });
      sendGcli('set_user_data', res);
      dispatch({
        type: "SET_AUTH_STATUS",
        payload: "success",
      });
      getAccountSettings(res.idToken).then(res => {
        const {sportLanguages} = store.getState();
        console.log(sportLanguages, res.language);
        res.language && dispatch({
          type: "SWITCH_LANG", payload: sportLanguages.includes(res.language) ? res.language : sportLanguages[0]
        });
        res.view && dispatch({type: "SWITCH_VIEW", payload: res.view});
        res.theme && dispatch({type: "SAVE_USER_THEME", payload: res.theme});
      });
    } else {
      dispatch({
        type: "SET_AUTH_STATUS",
        payload: "error",
      });
      dispatch({
        type: "SET_PHONE_PIN",
        payload: {
          phone: "",
          pin: "",
        },
      });
    }
  });
};

export const refreshV3 = (data: string) => {
  refreshTokens(data)
    .then((res) => {
      dispatch({
        type: "REFRESH_TOKENS",
        payload: {
          idToken: res.idToken,
          accessToken: res.accessToken,
          refreshToken: res.refreshToken,
          gamblerName: res.gamblerName,
          delayForRefreshTokens: res.delayForRefreshTokens,
        },
      });
      sendGcli('set_user_data', res);
      getAccountSettings(res.idToken).then(res => {
        const {sportLanguages} = store.getState();
        console.log(sportLanguages, res.language);
        res.language && dispatch({
          type: "SWITCH_LANG", payload: sportLanguages.includes(res.language) ? res.language : sportLanguages[0]
        });
        res.view && dispatch({type: "SWITCH_VIEW", payload: res.view});
        res.theme && dispatch({type: "SAVE_USER_THEME", payload: res.theme});
      });
    })
    .catch((err) => {
      console.error("Refresh token error! ", err);
      errorsHandler(102);
      setTimeout(() => errorsHandler(null), 5000);
      signOut();
    });
};

export const setRefreshHandle = (handle: any) => {
  dispatch({
    type: "SET_REFRESH_HANDLE",
    payload: {
      refreshHandle: handle
    }
  })
};

const loadImage = async (src:string) => {
  const headers = new Headers();
  headers.set('Authorization', `Basic ${btoa(`${
    process.env.NEXT_PUBLIC_IMAGE_BASE_USER || 'notification'
  }:${
    process.env.NEXT_PUBLIC_IMAGE_BASE_PASSWORD || 'eengahGeap2ohlei'
  }`)}`);
  const fetch_hueta = await fetch(src.replace('http:', 'https:'), {
    method: 'GET',
    headers,
  });
  const blob = await fetch_hueta.blob();
  return URL.createObjectURL(blob);
};

export const subscribeToV3Events = (
  { idToken, accessToken }: { idToken: string; accessToken: string },
  currentBalanceId: string,
  subscribeInterval: number,
  wsToV3: WebSocket | null
) => {
  const {isNotificationsEnabled} = store.getState();
  const subscribeData = [{
    id: `${uuidv4()}`,
    locale: "ru",
    domain: "subscribe",
    event: "subscribeAccountBalancesChanges",
    data: {
      interval: subscribeInterval,
    },
    tokens: {
      id: idToken,
      access: accessToken,
    },
  }];

  if (true || isNotificationsEnabled) {
    subscribeData.push({
      id: `${uuidv4()}`,
      locale: "ru",
      domain: "subscribe",
      event: "subscribeClientNotificationChanges",
      data: {
        interval: 20
      },
      tokens: {
        id: idToken,
        access: accessToken
      }
    });
  }

  if (!wsToV3) {
    const socket = new WebSocket(`${process.env.NEXT_PUBLIC_V3_URL_WS}`);

    socket.onopen = () => {
      console.debug(`Соединение установлено. Socket Ready State = ${socket.readyState}`);
      for (const data of subscribeData) socket.send(JSON.stringify(data));
    };

    socket.onerror = (err) => {
      console.error("Websocket error observed:", err);
    };

    socket.onclose = (ev) => {
      if (ev.wasClean) {
        console.debug(ev.reason);
        return;
      }

      console.debug("Соединение было сброшено");
    };

    socket.onmessage = async (ev) => {
      const data = JSON.parse(ev.data);
      switch(data.data && data.data.subscribeName) {
        case "wallet":
          dispatch({
            type: "PARSE_BALANCE",
            payload: data.data.balance,
          });
          if (currentBalanceId === "") {
            setBalanceId("money");
          }
          if (data.data.bonus) {
            dispatch({
              type: "PARSE_BONUS",
              payload: data.data.bonus,
            });
          }
          break;
        case "freeBet":
          dispatch({ type: "PARSE_FREEBETS", payload: data.data.freeBets });
          break;
        case "clientNotification":
          const {clientNotifications} = data.data;
          const notifications = clientNotifications.map((notification:any) => {
            const {
              clientNotificationId: notificationId,
              title = '',
              body: description = '',
              image = '',
              promoButton
            } = notification;
            return {
              notificationId,
              title,
              description,
              image,
              isRead: !!notification.readDttm,
              date: new Date(notification.beginDttm || Date.now()),
              promoButton
            };
          });

          for (const notification of notifications) {
            if (!notification.image) continue;
            try {
              notification.image = await loadImage(notification.image);
            } catch (error) {
              console.error(error);
            }
          }
          dispatch({type: "SET_NOTIFICATIONS", payload: notifications});
          break;
        default:
          if (data.data && data.data.errors && data.data.errors.length) {
            console.debug("Error while try re-subscribe - ", data.data.errors[0].message);
          } else console.debug("Unknown event:", data);
      }
    };

    dispatch({
      type: "SET_WS_TO_V3",
      payload: socket,
    });
  } else {
    console.debug("Переподписываемся без открытия нового соединения");
    for (const data of subscribeData) wsToV3.send(JSON.stringify(data));
  }
};

export const setBalanceId = (id: string) => {
  dispatch({
    type: "SET_CURRENT_BALANCE_ID",
    payload: id,
  });
};

export const sendBalance = (
  idToken: string,
  digitainApiToken: string,
  freebetsFromServer: Array<{
    beginDatetime: string;
    betMaxFactor: number;
    betMinFactor: number;
    endDatetime: string;
    freeBetId: string;
    value: number;
  }>,
  currentBalanceId: string
) => {
  const tokenPayload = jwt.decode(idToken);
  if (currentBalanceId === "money") {
    axios
      .post(`${getAuthServiceUrl()}/balance-type`, {
        token: `${digitainApiToken}`,
        balanceType: {
          idTokenPayload: tokenPayload,
          type: "wallet",
          data: {},
        },
      })
      .then((res) => console.debug("Digitain response", res))
      .catch((err) => {
        console.error("Error while change balance-type ", err);
      });
  } else {
    const currentFreebet = freebetsFromServer.find(
      (el) => el.freeBetId === currentBalanceId
    );
    axios
      .post(`${getAuthServiceUrl()}/balance-type`, {
        token: `${digitainApiToken}`,
        balanceType: {
          idTokenPayload: tokenPayload,
          type: "freeBet",
          data: currentFreebet,
        },
      })
      .then((res) => console.debug("Digitain response", res))
      .catch((err) => {
        console.error("Error while change balance-type ", err);
      });
  }
};

export const createSportFrame = (
  digitainApiToken: string,
  currentPage: string,
  sportUrl: string,
  theme: "dark" | "light",
  lang: string,
) => {
  console.debug("(window as any).SportFrame = ", (window as any).SportFrame);
  const iframeParentDomain: any = process.env.NEXT_PUBLIC_IFRAME_PARENT_DOMAIN;
  const sp = [
    ["server", `${sportUrl}/`],
    ["token", `${digitainApiToken}`],
    ["currentPage", currentPage],
    ["device", "d"],
    ["language", lang],
    ["theme", theme],
    ["fixedHeight", true],
    ["parent", iframeParentDomain.split(' ')]
    // ["customCssUrl", encodeURIComponent(`${process.env.NEXT_PUBLIC_STATIC_URL}`)]
  ];
  if (!(window as any).SportFrame.elem) {
    console.debug('First createSportFrame with params', sp);
    (window as any).SportFrame.frame(sp);
  } else {
    const sportIframeDiv = document.getElementById('sport_div_iframe');
    console.debug('Rebuild SportFrame with params', sportUrl, digitainApiToken, lang, theme);

    (window as any).SportFrame.setts.token = digitainApiToken;
    (window as any).SportFrame.setts.theme = theme;
    (window as any).SportFrame.setts.language = lang;
    (window as any).SportFrame.setts.server = `${sportUrl}/`;

    const genUrl = (window as any).SportFrame.buildUrl();

    console.debug('SportFrame generated url:', genUrl, ' apply to SportFrame elem:', (window as any).SportFrame.elem);
    (window as any).SportFrame.elem.src = genUrl;

    if(sportIframeDiv && (window as any).SportFrame.elem.parentElement !== sportIframeDiv) {
      console.debug('SportFrame is not binded to div! Rebind from:', (window as any).SportFrame.elem.parentElement);
      sportIframeDiv.appendChild((window as any).SportFrame.elem);
    }
  }
};

export const createCybersportFrame = async (
  digitainApiToken: string,
  sportUrl: string,
  view: "ESport" | "LatinoView",
  theme: "dark" | "light",
  lang: string,
  cb: Function,
) => {
  const params = {
    server: `${sportUrl}/`,
    containerId: "application-container",
    token: `${digitainApiToken}`,
    defaultLanguage: lang,
    theme
  };
  if(cyberSportFrame) {
    // TODO: change params in existing frame
    console.debug('CyberSportFrame exists:', cyberSportFrame);
    await cyberSportFrame.destroy();
  }
  cyberSportFrame = await (window as any).Bootstrapper.bootIframe(params, {name: view});
  console.debug('CyberSportFrame created:', cyberSportFrame);
  cb(cyberSportFrame);
  return cyberSportFrame;
};

export const createLinkList = () => {
  const linkList = [
    {
      name: "Регламент",
      func() {
        toggleModal();
      },
    },
    {
      name: "Спорт",
      func() {
        dispatch({
          type: "NAVIGATE_CLICK",
          payload: {
            section: "sport",
            currentPage: "Upcoming",
          },
        });
      },
    },
    {
      name: "Live",
      func() {
        dispatch({
          type: "NAVIGATE_CLICK",
          payload: {
            section: "sport",
            currentPage: "Overview",
          },
        });
      },
    },
    /* {
      name: "Киберспорт",
      func() {
        dispatch({
          type: "NAVIGATE_CLICK",
          payload: {
            section: "cybersport",
            currentPage: "Home",
          },
        });
      },
    }, */
  ];

  return linkList;
};

export const openBetsHistory = () => {
  const {section, currentPage} = store.getState();
  if (currentPage === "BetsHistory"){
    dispatch({
      type: 'BETHISTORY_OPEN'
    })
  }
  dispatch({type: "NAVIGATE_CLICK", payload: {section, currentPage: "BetsHistory"}});
};

export const clearUserData = () => {
  const {wsGcli} = store.getState();
  const gcliMessage = JSON.stringify({
    kind: "clear_user_data",
  });
  console.debug('clearUserData()', gcliMessage, wsGcli)
  if(wsGcli){
    wsGcli.send(gcliMessage);
  }
};

export const logOut = () => {
  const {wsToV3, wsGcli} = store.getState();
  wsToV3?.close();
  clearUserData();
  dispatch({
    type: "LOGOUT",
  });
  dispatch({
    type: "RESET_TOKENS",
  });
  const gCliMessage = JSON.stringify({
    kind: "exit_game_msg",
  });
  if (wsGcli) {
    wsGcli.send(gCliMessage);
  }
};

export const signOut = async (needReload = false) => {
  console.debug(`signOut(). needrReload=${needReload}`)
  const {wsToV3, wsGcli, digitainApiToken, walletId} = store.getState();
  console.debug(`signOut(). walletId=${walletId}`)

  wsToV3?.close();

  const gCliMessage = JSON.stringify({
    kind: "logout_msg",
  });

  clearUserData();

  if (wsGcli) {
    wsGcli.send(gCliMessage);
  }


  dispatch({
    type: "LOGOUT",
  });
  dispatch({
    type: "RESET_TOKENS",
  });

  axios.post(`${getAuthServiceUrl()}/signout`, { token: digitainApiToken})
      .then(() => {
        console.debug("Signout partner-auth-service success");
      })
      .catch((err) => {
        console.debug("Signout partner-auth-service error", err);
      });

  if(prolongTimeout){
    clearTimeout(prolongTimeout);
    console.debug("prolongTimeout was clear");
  }
  if(needReload && !walletId){
    document.location.reload();
  }
};

export const errorsHandler = (errorCode: number | null) => {
  if (errorCode) {
    dispatch({
      type: "SHOW_ERROR",
      payload: errorCode,
    });
  } else if (errorCode === null) {
    dispatch({
      type: "HIDE_ERROR",
    });
    setTimeout(
      () =>
        dispatch({
          type: "REFRESH_ERROR_TEXT",
        }),
      1000
    );
  }
};

export const setSettings = (data: any) => {
  const {view, userTheme, idToken, sportLanguages} = store.getState();
  switch(false) {
    case !data.view:
      dispatch({type: "SWITCH_VIEW", payload: data.view});
      dispatch({type: "SWITCH_THEME", payload: data.view === "LatinoView" ? "dark" : userTheme});
      setAccountSettings(idToken, data);
      break;
    case !data.language:
      const language = sportLanguages.includes(data.language) ? data.language : sportLanguages[0];
      if (view === "ESport") {
        window.SportFrame.setts.language = language;
        window.SportFrame.elem.src = window.SportFrame.buildUrl();
      } else {
        cyberSportFrame.dispatch("setLanguage", language);
      }
      dispatch({type: "SWITCH_LANG", payload: language});
      if(data.language === language) setAccountSettings(idToken, data);
      break;
    case !data.theme:
      if (view === "ESport") {
        window.SportFrame.setts.theme = data.theme;
        window.SportFrame.setTheme(data.theme);
      } else {
        cyberSportFrame.dispatch("setTheme", data.theme);
      }
      dispatch({type: "SAVE_USER_THEME", payload: data.theme});
      setAccountSettings(idToken, data);
      break;
    default: console.warn('Something wrong!', data);
  }
};

export const readNotificationsFunc = (clientNotificationId:number) => {
  const {idToken, notifications} = store.getState();
  const notification = notifications.find(({notificationId}:notificationType) => notificationId === clientNotificationId);
  if (notification) {
    notification.isRead = true;
    dispatch({type: "SET_NOTIFICATIONS", payload: notifications});
  } else console.debug('Notification not found!', clientNotificationId, notifications);
  readClientNotification(idToken, {clientNotificationId});
};

export const pressPromoFunc = (clientNotificationId:number) => {
  const {idToken} = store.getState();
  pressNotification(idToken, {clientNotificationId, accountId: "0"});
};